const initialState = {
  prices: [
    {
      name: "Karnet 4 wejścia",
      price: "149 zł",
      description: "1 raz w tygodniu",
      singlePrice: "(37,25 zł jedno wejście)"
    },
    {
      name: "Karnet 8 wejść",
      price: "249 zł",
      description: "2 razy w tygodniu",
      singlePrice: "(31,12 zł jedno wejście)"
    },
    {
      name: "Student/Uczeń 4 wejścia",
      price: "139 zł",
      description: "1 raz w tygodniu",
      singlePrice: "(34,75 zł jedno wejście)"
    },
    {
      name: "Student/Uczeń 8 wejść",
      price: "239 zł",
      description: "2 razy w tygodniu",
      singlePrice: "(29,87 zł jedno wejście)"
    },
    // {
    //   name: "Karnet dziecko 4 wejścia",
    //   price: "76 zł",
    //   description: "1 raz w tygodniu",
    //   singlePrice: "(19 zł jedno wejście)"
    // },
    // {
    //   name: "Karnet Formacja",
    //   price: "219 zł",
    //   description: "2h zajęć w tygodniu",
    //   singlePrice: "(27,30 zł jedno wejście)"
    // }
  ],
  individualLessons: [
    {
      name: "1 lekcja",
      price: "170 zł",
      description: "zajęcia indywidualne z instruktorem",
      singlePrice: "(170 zł za jedną lekcję 60min)"
    },
    {
      name: "Pakiet 8 lekcji",
      price: "1280 zł",
      description: "zajęcia indywidualne z instruktorem",
      singlePrice: "(160 zł za jedną lekcję 60min)"
    }
  ],
  proAm: [
    {
    name: "1 lekcja",
    price: "180 zł/h",
    description: "zajęcia indywidualne profesjonalista - amator"
  },
  {
    name: "Pakiet 8 lekcji",
    price: "1360zł",
    description: "zajęcia indywidualne profesjonalista - amator",
    singlePrice: "(170zł za jedną lekcje 60min)"
  }
  ],
  roomRenting: [
    {
      name: "Dowolna Sala od poniedziałku do piątku do 17:00",
      price: "50 zł/h",
      description: "wynajem sali"
    },
    {
      name: "Dowolna Sala od poniedziałku do piątku od 17:00",
      price: "60 zł/h",
      description: "wynajem sali"
    },
    {
      name: "Dowolna Sala weekend",
      price: "60 zł/h",
      description: "wynajem sali"
    }
  ]
};

const pricesReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default pricesReducer;
